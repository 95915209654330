////////////////////////////////Constants///////////////////////////////////////////////////

export const loggingUser = "/auth/admin-login";
export const userProfile = "/auth/admin/profile";
export const forgetPasswordEmail = "/auth/admin/otp";
export const forgetPasswordOtp = "/auth/admin/verify";
export const resetForgetPassword = "/auth/admin/password";
export const changingPassword = "/dashboard/password";
export const addingUser = "/register";
export const gettingRecentUsers = "/dashboard/get_recent_users";
export const dashboardData = "/dashboard";
export const settings = "/setting";

/////////////////////////////////Functions///////////////////////////////////////////////////

export const gettingAllUser = (page = 1, search = null) => {
  if (search !== null) {
    return `/dashboard/user?page=${page * 1}&search=${search}`;
  } else {
    return `/dashboard/user?page=${page}`;
  }
};

export const gettingUser = (data) => {
  return `/dashboard/user-detail/${data}`;
};

export const updatingUser = (id) => {
  return `/dashboard/update-user/${id}`;
};

export const updateStatus = (id) => {
  return `/dashboard/change-status/${id}`;
};

export const deletingUser = (id) => {
  return `/dashboard/delete-user/${id}`;
};

export const gettingAllContact = (page = 1, search = null) => {
  if (search !== null) {
    return `/dashboard/user?page=${page * 1}&search=${search}`;
  } else {
    return `/dashboard/contact?page=${page}`;
  }
};

export const contactDetail = (id) => {
  return `/dashboard/contact/${id}`
};

export const gettingAllReport = (page = 1, search = null) => {
  if (search !== null) {
    return `/dashboard/user?page=${page * 1}&search=${search}`;
  } else {
    return `/dashboard/report?page=${page}`;
  }
};

export const reportDetail = (id) => {
  return `/dashboard/report/${id}`
};