import axios from "../../instance";
import * as url from "../../constants/urlConstants";

export const DASHBOARD_DATA = "DASHBOARD_DATA";

export const getDashboardData = (data) => {
  return {
    type: DASHBOARD_DATA,
    data: data,
  };
};

export const gettingDashboardData = () => {
  return async (dispatch) => {
    const res = await axios.get(url.dashboardData);
    if (res && res.status && res.status === 200) {
      dispatch(getDashboardData(res.data.data));
    } else if (res && res.success === false && res.message === "No user id") {
      return 404;
    } else {
      return 500;
    }
  };
};
