import axios from "axios";

const instance = axios.create({
  baseURL: "https://honkt.dmlabs.in/api/v1",
});

instance.interceptors.request.use(
  async (config) => {
    config.headers["x-access-token"] = await localStorage.getItem("honkt-token");
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (err) => {
    const originalRequest = err.config;
    let refreshToken = localStorage.getItem("refreshToken");
    if (err?.response?.data?.httpCode === 400) {
      return err.response.data;
    } else if (
      refreshToken &&
      (err.response.status === 401)
    ) {
      let res = await instance.post(`/auth/refresh-token`, {
        refreshToken: refreshToken,
      });
      if (res.status && res.status === 200) {
        unAutherized(res.data.data);
        originalRequest.headers = {
          "x-access-token": res.data.data.accessToken,
        };
        return instance(originalRequest);
      } else if (res.httpCode && res.httpCode === 400) {
        loggingOutUser();
      }
    } else if (!refreshToken && err?.response?.data?.httpCode === 401) {
      window.location.href = "/login";
    }
     else {
      return err && err.response && err.response.data
        ? err.response.data
        : { status: 500 };
    }
  }
);

const unAutherized = async (data) => {
  localStorage.setItem("refreshToken", data.refreshToken);
  localStorage.setItem("honkt-token", data.accessToken);
};

const loggingOutUser = () => {
  if (localStorage.getItem("honkt-token")) {
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("honkt-token");
    window.location.href = "/login";
  }
};

export default instance;
