import * as actionTypes from "../actions/dashboardAction";

const initialState = {
    data: {}
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.DASHBOARD_DATA: {
            return {
                ...state,
                data: action.data,
            };
        }
    }
    return state;
};

export default reducer;
