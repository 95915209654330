import * as actionTypes from "../actions/feedbackAction";

const initialState = {
    contacts: [],
    reports: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ALL_CONTACT: {
            return {
                ...state,
                contacts: action.data.data,
                pages: action.data.meta.totalRecords
            };
        }
        case actionTypes.GET_CONTACT: {
            return {
                ...state,
                contact: action.data,
            }
        }
        case actionTypes.GET_ALL_REPORT: {
            return {
                ...state,
                reports: action.data.data,
                pages: action.data.meta.totalRecords
            };
        }
        case actionTypes.GET_REPORT: {
            return {
                ...state,
                report: action.data,
            }
        }
    }
    return state;
};

export default reducer;
