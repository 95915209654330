import * as actionTypes from "../actions/loginAction";

const initialState = {
  userInfo: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  }
  return state;
};

export default reducer;
