import axios from "../instance";
import React, { useReducer, createContext, useEffect } from "react";
import * as url from "../constants/urlConstants";

const authReducer = (state, action) => {
    switch (action.type) {
        case "LOGGED_IN_USER":
            return { ...state, user: action.payload };
        default:
            return state;
    }
};

const initialState = {
    user: null
};

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, initialState);

    const userProfile = async( )=> {
        if (localStorage.getItem('honkt-token')) {
            let res = await axios.get(url.userProfile);
            if (res?.data?.data) {
                let { name, profile_pic } = res.data.data;
                dispatch({
                    type: 'LOGGED_IN_USER',
                    payload: { name, profile_pic }
                });
            }
            else {
                dispatch({
                    type: 'LOGGED_IN_USER',
                    payload: null
                })
            }
        }
    };

    useEffect(() => {
        userProfile()
    }, []);

    const value = { state, dispatch };
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };