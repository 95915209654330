import * as url from "../../constants/urlConstants";
import axios from "../../instance";

export const loggingUser = (data) => {
  return async (dispatch) => {
    const res = await axios.post(url.loggingUser, data);
    if (res.status && res.status === 200) {
      localStorage.setItem("honkt-token", res.data.data.accessToken);
      localStorage.setItem("refreshToken", res.data.data.refreshToken);
    }
    return res;
  };
};

export const loggingOut = () => {
  return async (dispatch) => {
    await localStorage.removeItem("honkt-token");
    await localStorage.removeItem("refreshToken");
    return true;
  };
};
