import * as url from "../../constants/urlConstants";
import axios from "../../instance";

export const GET_SETTINGS = "GET_SETTINGS";

export const getSettings = (data) => {
    return {
        type: GET_SETTINGS,
        data: data,
    };
};

export const gettingSettings = () => {
    return async (dispatch) => {
        const res = await axios.get(url.settings);
        if (res?.status === 200) {
            dispatch(getSettings(res.data.data));
        } else if (res?.data?.httpCode === 400) {
            return 400;
        } else {
            return 500;
        }
    };
};

export const updatingSettings = (data) => {
    return async (dispatch) => {
        const res = await axios.post(url.settings, data);
        if (res?.status === 200) {
            return res;
        } else if (res?.status === 400) {
            return 400;
        }
    };
};
