import axios from "../../instance";
import * as url from "../../constants/urlConstants";

export const GET_ALL_CONTACT = "GET_ALL_CONTACT";
export const GET_CONTACT = "GET_CONTACT";
export const GET_ALL_REPORT = "GET_ALL_REPORT";
export const GET_REPORT = "GET_REPORT";

export const getContact = (data) => {
  return {
    type: GET_CONTACT,
    data: data,
  };
};

export const gettingContactDetail = (id) => {
  return async (dispatch) => {
    const res = await axios.get(url.contactDetail(id));
    if (res && res.status && res.status === 200) {
      dispatch(getContact(res.data.data));
    } else if (res && res.success === false && res.message === "No contact id") {
      return 404;
    } else {
      return 500;
    }
  };
};

export const getAllContact = (data) => {
  return {
    type: GET_ALL_CONTACT,
    data: data
  };
};

export const gettingContact = (page) => {

  return async (dispatch) => {
    try {
      const res = await axios.get(url.gettingAllContact(page));
      if (res && res.data && res.data.success === false) {
        return res;
      } else if (res && res.status && res.status === 500) {
        return 500;
      } else if (
        res &&
        res.data &&
        res.data.data &&
        res.data.data.length === 0
      ) {
        dispatch(getAllContact(res.data));
        return 404;
      } else {
        dispatch(getAllContact(res.data));
        return 200;
      }
    }
    catch (error) {
      return;
    }
  };
};

export const getAllReport = (data) => {
  return {
    type: GET_ALL_REPORT,
    data: data
  };
};

export const gettingReport = (page) => {
  return async (dispatch) => {
    try {
      const res = await axios.get(url.gettingAllReport(page));
      if (res && res.data && res.data.success === false) {
        return res;
      } else if (res && res.status && res.status === 500) {
        return 500;
      } else if (
        res &&
        res.data &&
        res.data.data &&
        res.data.data.length === 0
      ) {
        dispatch(getAllReport(res.data));
        return 404;
      } else {
        dispatch(getAllReport(res.data));
        return 200;
      }
    }
    catch (error) {
      return;
    }
  };
};

export const getReport = (data) => {
  return {
    type: GET_REPORT,
    data: data,
  };
};

export const gettingReportDetail = (id) => {
  return async (dispatch) => {
    const res = await axios.get(url.reportDetail(id));
    if (res && res.status && res.status === 200) {
      dispatch(getReport(res.data.data));
    } else if (res && res.success === false && res.message === "No contact id") {
      return 404;
    } else {
      return 500;
    }
  };
};