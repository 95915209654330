import * as actionTypes from "../actions/settingAction";

const initialState = {
    settings: {}
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_SETTINGS: {
            return {
                ...state,
                settings: action.data,
            }
        }
        default: return state;
    }
};

export default reducer;
